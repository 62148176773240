<style scoped>
  @media print {
    .no-print {
      display: none !important;
    }
  }

  .floating-button-wrapper {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
  }

  .floating-button {
    border-radius: 50%;
  }
</style>

<template>
  <div class="floating-button-wrapper">
    <a
      slot="trigger"
      class="button floating-button is-success is-large"
      :title="$t('common.add')"
      @click="checkSanisId"
    >
      <b-icon icon="plus" />
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CheckSanisId from './CheckSanisId'

export default {
  methods: {
    checkSanisId () {
      const self = this
      this.$buefy.modal.open({
        parent: self,
        component: CheckSanisId,
        hasModalCard: true,
        fullScreen: true,
        width: 400,
        height: 400
      })
    }
  }
}
</script>
